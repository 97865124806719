#root {
  min-height: 100vh;
}
html,
body,
#root {
  min-height: 100% !important;
  height: 100%;
}

body{
  padding: 0!important;
}

.rdrMonthAndYearWrapperCustom{
  visibility: hidden;
}

.rdrDateRangeWrapperCustom{
  width: 350px;
}

@font-face {
  font-family:"acumin-pro";
  src:url("https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
  }

@import url("https://p.typekit.net/p.css?s=1&k=uvo3zas&ht=tk&f=28422.28423.28426.28431.38003.38004.38005&a=159266807&app=typekit&e=css");

@font-face {
font-family:"mr-eaves-xl-modern";
src:url("https://use.typekit.net/af/f64800/00000000000000007735b303/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff2"),url("https://use.typekit.net/af/f64800/00000000000000007735b303/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff"),url("https://use.typekit.net/af/f64800/00000000000000007735b303/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:800;font-stretch:normal;
}

@font-face {
font-family:"mr-eaves-xl-modern";
src:url("https://use.typekit.net/af/85cac6/00000000000000007735b307/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/85cac6/00000000000000007735b307/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/85cac6/00000000000000007735b307/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"mr-eaves-xl-modern";
src:url("https://use.typekit.net/af/fdd6d6/00000000000000007735b313/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3") format("woff2"),url("https://use.typekit.net/af/fdd6d6/00000000000000007735b313/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3") format("woff"),url("https://use.typekit.net/af/fdd6d6/00000000000000007735b313/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:200;font-stretch:normal;
}

@font-face {
font-family:"mr-eaves-xl-modern";
src:url("https://use.typekit.net/af/e35fa0/00000000000000007735b327/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/e35fa0/00000000000000007735b327/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/e35fa0/00000000000000007735b327/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"ivyjournal";
src:url("https://use.typekit.net/af/329ac4/00000000000000007735c08c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/329ac4/00000000000000007735c08c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/329ac4/00000000000000007735c08c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"ivyjournal";
src:url("https://use.typekit.net/af/5c8320/00000000000000007735c090/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff2"),url("https://use.typekit.net/af/5c8320/00000000000000007735c090/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff"),url("https://use.typekit.net/af/5c8320/00000000000000007735c090/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:600;font-stretch:normal;
}

@font-face {
font-family:"ivyjournal";
src:url("https://use.typekit.net/af/d983b3/00000000000000007735c094/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/d983b3/00000000000000007735c094/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/d983b3/00000000000000007735c094/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}
@font-face {
font-family:"fractul-variable";
src:url("https://use.typekit.net/af/d7bd04/00000000000000007753f6cd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("woff2"),url("https://use.typekit.net/af/d7bd04/00000000000000007753f6cd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("woff"),url("https://use.typekit.net/af/d7bd04/00000000000000007753f6cd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:50 900;font-stretch:normal;
}

@font-face {
font-family:"violeta-jardinera";
src:url("https://use.typekit.net/af/819956/000000000000000077588ae9/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/819956/000000000000000077588ae9/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/819956/000000000000000077588ae9/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"violeta";
src:url("https://use.typekit.net/af/751219/000000000000000077588aea/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/751219/000000000000000077588aea/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/751219/000000000000000077588aea/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"violeta";
src:url("https://use.typekit.net/af/e00143/000000000000000077588aed/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/e00143/000000000000000077588aed/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/e00143/000000000000000077588aed/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}
	

.tk-mr-eaves-xl-modern { font-family: "mr-eaves-xl-modern",sans-serif; }
.tk-ivyjournal { font-family: "ivyjournal",sans-serif; }
.tk-fractul-variable { font-family: "fractul-variable",sans-serif; }
.tk-violeta-jardinera { font-family: "violeta-jardinera",sans-serif; }
.tk-violeta { font-family: "violeta",sans-serif; }



.containerCert > ::-webkit-scrollbar {
  width: 10px;
 
}

/* Track */
.containerCert > ::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.containerCert > ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.containerCert > ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

div.highway-slider {
	display:flex;
	justify-content:center;
	width:100%;
	height:150px;
	div.highway-barrier {
		overflow:hidden;
		position:relative;
	}
	ul.highway-lane {
		display:flex;
		height:100%;
		li.highway-car {
			flex:1;
			display:flex;
			justify-content:center;
			align-items:center;
			background:#fff;
			color:#343434;
		}
	}
}

@keyframes translateinfinite {
	100% { transform:translateX(calc(-180px * 5));  }
}
#infinite div.highway-barrier {
	background:#fff;
	box-shadow:0 3px 10px -3px rgba(0,0,0,0.3);
	ul.highway-lane {
		width:calc(180px * 10);
		li.highway-car {
			width:180px;
			animation:translateinfinite 25s linear infinite;
		}
	}
}

.gradient-background {
	background: linear-gradient(180deg, rgba(120, 90, 236, 0) 33.56%, rgba(20, 0, 61, 0.85) 100%);
  }
